import React from "react";
import {redirectPath} from "../config/productDetails";

interface SliderImageProps {
    href: string;
    title: string;
    img: string;
}

export class SliderImage extends React.Component<SliderImageProps> {

    render() {
        return (
            <div>
                <div className={"d-inline-block position-relative mx-auto w-auto"}>
                    <a href={this.props.href}>
                        <img alt={this.props.title} className="mw-100 d-block" src={this.props.img}/>
                        <span className={"h-100 w-100 opacity-hover d-flex align-items-center justify-content-center" +
                        " text-white"}
                              style={{top: "0", position: "absolute", backgroundColor: "rgba(0, 0, 0, 0.5)"}}>
                            {this.props.title}
                        </span>
                    </a>
                </div>
                <div className={"d-flex align-items-center justify-content-center d-md-none text-white" +
                " link-undecorated"} style={{height: "50px"}}>
                    {this.props.title}
                </div>
            </div>
        );
    }
}