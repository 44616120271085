import React from "react";
import CookieConsent, {Cookies} from "react-cookie-consent";
import {renderGoogleAnalytics} from "../index";

export const cookieName = "kennzeichen-zulassungsservice-cookie-consent";

export class CookiesView extends React.Component {

    onAccept = () => {
        renderGoogleAnalytics();
    }

    render() {
        return (
            <>
                <CookieConsent
                    location="bottom"
                    buttonText="Alle zulassen"
                    cookieName={cookieName}
                    style={{
                        background: "#233882",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "0"
                    }}
                    buttonClasses={"button bg-white"}
                    enableDeclineButton
                    declineButtonText={"Notwendige zulassen"}
                    declineButtonClasses={"button button-primary"}
                    onAccept={this.onAccept.bind(this)}
                    hideOnAccept
                    expires={300}
                >
                    Diese Seite verwendet Cookies, um das Nutzerverhalten zu verstehen.
                </CookieConsent>
            </>
        );
    }
}