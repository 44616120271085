import {redirectPath} from "./productDetails";

const produktGalerie = [
    {
        img: "/images/produktgalerie/produkt1.jpg",
        title: "Autokennzeichen",
        href: redirectPath("/produkt/autokennzeichen")
    },
    {
        img: "/images/produktgalerie/produkt2.jpg",
        title: "H-Kennzeichen, Saisonkennzeichen",
        href: redirectPath("/produkt/autokennzeichen?h=true&saison=true")
    },
    {
        img: "/images/produktgalerie/produkt3.jpg",
        title: "Zweizeilige Kennzeichen",
        href: redirectPath("/produkt/autokennzeichen")
    },
    {
        img: "/images/produktgalerie/produkt4.jpg",
        title: "Motorradkennzeichen",
        href: redirectPath("/produkt/motorradkennzeichen")
    },
    {
        img: "/images/produktgalerie/produkt5.jpg",
        title: "Parkplatzschilder",
        href: redirectPath("/produkt/parkplatzschilder")
    },
    {
        img: "/images/produktgalerie/produkt6.jpg",
        title: "Grüne Kennzeichen, Steuerbefreite Kennzeichen",
        href: redirectPath("/produkt/autokennzeichen?steuerbefreit=true")
    }
];

const zubehoerGalerie = [
    {
        img: "/images/zubehoergalerie/zubehoer1.jpg",
        title: "48x19mm Schraubköpfe",
        href: redirectPath("/produkt/kennzeichenschrauben-48-x-19mm")
    },
    {
        img: "/images/zubehoergalerie/zubehoer2.jpg",
        title: "55x19mm Schrauben",
        href: redirectPath("/produkt/kennzeichenschrauben-55-x-19-mm")
    },
    {
        img: "/images/zubehoergalerie/zubehoer3.jpg",
        title: "Kennzeichenhalter Klemmfix",
        href: redirectPath("/produkt/klemmfix-kennzeichenhalter-schwarz")
    },
    {
        img: "/images/zubehoergalerie/zubehoer4.jpg",
        title: "Kennzeichenhalter schwarz",
        href: redirectPath("/produkt/kennzeichenhalter-in-schwarz")
    },
    {
        img: "/images/zubehoergalerie/zubehoer5.jpg",
        title: "M5 Schraubköpfe",
        href: redirectPath("/produkt/kennzeichenschrauben-m5-x-20-mm")
    },
    {
        img: "/images/zubehoergalerie/zubehoer6.jpg",
        title: "M6 Schrauben",
        href: redirectPath("/produkt/kennzeichenschrauben-m6-x-20-mm")
    }
];

export { produktGalerie, zubehoerGalerie };