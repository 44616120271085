import React from "react";
import ReactDOM from "react-dom";
import "./style.css";
import {Home} from "./views/home";
import {cookieName, CookiesView} from "./views/cookies";
import {Cookies} from "react-cookie-consent";
import ReactGA from 'react-ga';

function renderCookieConsent() {
    ReactDOM.render(<CookiesView/>, document.getElementById("cookie-container"));
}

ReactDOM.render(<Home/>, document.getElementById("app"), () => renderCookieConsent());

renderGoogleAnalytics();

export function renderGoogleAnalytics() {
    if (Cookies.get(cookieName)) {
        ReactGA.initialize('G-K9QTYZSKH6');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
}