import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import {IconBox} from "../components/iconBox";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LazyLoad from "react-lazyload";
import {produktGalerie, zubehoerGalerie} from "../config/productSliders";
import {SliderImage} from "../components/sliderImage";
import {productDetails, ProductDetailType, redirectPath} from "../config/productDetails";

export class Content extends React.Component {

    listIconStyle = {
        color: "green",
        fontSize: "40px"
    };

    render() {

        let imgBoxClasses = "col-6 col-lg-3 mt-1 mt-sm-4 d-flex align-items-center justify-content-center px-1 px-sm-3";

        return (
            <main className="">
                <div id={"header-image"} style={{
                    backgroundImage: "url('/images/header-img.jpg')"
                }}>
                    <div className={"img-overlay"}>
                        <div className={"container text-right"}>
                            Kfz-Kennzeichen aller Art<br/>
                            ab 7,99€<br/>
                            <small>Inkl. gesetzl. MwSt.</small>
                        </div>
                    </div>
                </div>
                <div className={"container"}>
                    <div className={"row mt-negative"}>
                        <div className={"col-6 col-md-3 p-1 p-md-3 d-flex justify-content-center"}>
                            <div className={"card blue-card"}>
                                Premium Qualität
                                <img src={"/images/award.svg"} alt={"Hochwertige Autokennzeichen"}/>
                            </div>
                        </div>
                        <div className={"col-6 col-md-3 p-1 p-md-3 d-flex justify-content-center"}>
                            <div className={"card blue-card"}>
                                Bundesweit anerkannt
                                <img src={"/images/germany.svg"} alt={"Autokennzeichen Deutschland"}/>
                            </div>
                        </div>
                        <div className={"col-6 col-md-3 p-1 p-md-3 d-flex justify-content-center"}>
                            <div className={"card blue-card"}>
                                Faire Preise
                                <img src={"/images/piggy-bank.svg"} alt={"billige Autokennzeichen"}/>
                            </div>
                        </div>
                        <div className={"col-6 col-md-3 p-1 p-md-3 d-flex justify-content-center"}>
                            <div className={"card blue-card"}>
                                Kostenloser Versand
                                <img src={"/images/defizit.svg"} alt={"Kennzeichen günstig kaufen"}/>
                                <small>Schon ab 10,00€ Bestellwert!</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container py-3">
                    <div className="row">
                        <div className={"col-12 col-sm-4 col-lg-6 d-flex justify-content-center align-items-center" +
                        " justify-content-sm-end"}>
                            <LazyLoad>
                                <img className={"w-100"} style={{maxWidth: "300px"}} src={"/images/circle.svg"}
                                     alt={"Kennzeichen online" +
                                     " bestellen"}/>
                            </LazyLoad>
                        </div>
                        <div className={"col-12 col-sm-8 col-lg-6 d-flex align-items-center"}>
                            <ul>
                                <li><CheckIcon style={this.listIconStyle}/> Kennzeichen für nahezu jedes Fahrzeug</li>
                                <li><CheckIcon style={this.listIconStyle}/> Standard- und Sondergrößen</li>
                                <li><CheckIcon style={this.listIconStyle}/> Ein- und mehrzeilige Kennzeichen</li>
                                <li><CheckIcon style={this.listIconStyle}/> Passendes Zubehör</li>
                                <li><CheckIcon style={this.listIconStyle}/> Qualität Made in Germany</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <a id={"wunschkennzeichen-konfigurieren"} />

                <div className={"container py-3 mb-3"}>
                    <div className={"row"}>
                        <div className={imgBoxClasses}>
                            <IconBox img={"/images/car.svg"} href={redirectPath("/produkt/autokennzeichen")} text={"Autokennzeichen"}/>
                        </div>
                        <div className={imgBoxClasses}>
                            <IconBox img={"/images/container.svg"} href={redirectPath("/produkt/lkw-kennzeichen")} text={"LKW-Kennzeichen"}/>
                        </div>
                        <div className={imgBoxClasses}>
                            <IconBox img={"/images/quad.svg"} href={redirectPath("/produkt/motorradkennzeichen")} text={"Quad-Kennzeichen"}/>
                        </div>
                        <div className={imgBoxClasses}>
                            <IconBox img={"/images/motocross.svg"} href={redirectPath("/produkt/motorradkennzeichen")} text={"Motorrad-Kennzeichen"}/>
                        </div>
                        <div className={imgBoxClasses}>
                            <IconBox img={"/images/traktor.svg"} href={redirectPath("/produkt/traktorkennzeichen")} text={"Traktor-Kennzeichen"}/>
                        </div>
                        <div className={imgBoxClasses}>
                            <IconBox img={"/images/fahrradtraeger.svg"} href={redirectPath("/produkt/autokennzeichen")} text={"Fahrradträger-Kennzeichen"}/>
                        </div>
                        <div className={imgBoxClasses}>
                            <IconBox img={"/images/parking.svg"} href={redirectPath("/produkt/parkplatzschilder")} text={"Parkplatzschilder"}/>
                        </div>
                        <div className={imgBoxClasses}>
                            <IconBox img={"/images/zubehoer.svg"} href={redirectPath("/produkt/kfz-zubehoer")} text={"Kennzeichen-Zubehör"}/>
                        </div>
                    </div>
                </div>

                {/* Produkt-Galerie */}

                <div className={"mt-5 bg-custom-primary py-5 text-white text-center overflow-hidden"}>
                    <div className={"container px-1 pb-3"}>
                        <h4>Produktgalerie</h4>
                        <LazyLoad height={300}>
                            <Slider dots={true} infinite={true} speed={500} slidesToShow={3} autoplay slidesToScroll={1}
                                    centerMode={true} centerPadding={"0px"} className={"slider mt-5"}>
                                {produktGalerie.map((x, index) => {
                                    return (
                                        <div key={index} className="px-3">
                                            <a href={x.href}>
                                                <img alt={x.title} className="mw-100" src={x.img}/>
                                            </a>
                                        </div>);
                                })}
                            </Slider>
                        </LazyLoad>
                    </div>
                </div>

                {/* Details zu den Produkten */}
                <div className={"container"}>
                    {productDetails.map((data: ProductDetailType, index: number) => {
                        return (
                            <div className={"row"} key={index}>
                                <div
                                    className={`d-flex align-items-center justify-content-center text-center my-5 col-12 col-md-6 ${index % 2 === 0 ? "order-md-last" : ""}`}>
                                    <LazyLoad>
                                        <a href={data.href}><img style={{maxWidth: "400px"}} src={data.img}
                                                                 alt={data.title}/>
                                        </a>
                                    </LazyLoad>
                                </div>
                                <div
                                    className={`d-flex flex-column text-center my-1 my-md-5 col-12 col-md-6 ${index % 2 === 0 ? "order-md-first" : ""}`}>
                                    <h2>{data.title}</h2>
                                    {data.text}
                                    <div className={"mt-5"}>
                                        <a href={data.href} className={"button button-primary"}>{data.buttonText}</a>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>


                {/* Zubehör-Galerie */}

                <div className={"mt-3 bg-custom-primary py-5 text-white text-center overflow-hidden"}>
                    <div className={"container px-1 pb-3"}>
                        <h4>Passendes Zubehör für Ihr Kfz-Kennzeichen</h4>
                        <LazyLoad height={300}>
                            <Slider dots={true} infinite={true} speed={500} slidesToShow={3} autoplay={false}
                                    slidesToScroll={1}
                                    centerMode={true} centerPadding={"0px"} className={"slider mt-5"}>
                                {zubehoerGalerie.map((x, index) => {
                                    return (
                                        <div key={index} className="px-3 position-relative">
                                            <SliderImage href={x.href} title={x.title} img={x.img}/>
                                        </div>);
                                })}
                            </Slider>
                        </LazyLoad>
                    </div>
                </div>
                {
                    /*<div className="overlap-cta-container">
                      <div className="overlap-cta-outer">
                        <div className="overlap-cta-inner shadow rounded p-3 text-center">
                          <h4>Geben Sie gleich hier Ihr Kennzeichen ein</h4>

                          <EasyPreview/>
                        </div>
                      </div>
                      <div className="my-3 bg-custom-primary-light w-100 text-white pt-3">
                        <div className="container py-3 mt-3">Text</div>
                      </div>
                    </div>
                     */
                }
            </main>
        );
    }
}
