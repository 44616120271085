import React from "react";
import { Content } from "./content";
import { Footer } from "./footer";
import { Header } from "./header";

export class Home extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Content />
        <Footer />
      </>
    );
  }
}
