import React from "react";
import {Row, Col} from "react-bootstrap";
import LazyLoad from "react-lazyload";
import {redirectPath} from "../config/productDetails";

export class Header extends React.Component {
    render() {
        return (
            <>
                <header className="px-1 px-sm-3 shadow d-flex align-items-center position-fixed fixed-top shadow">
                    <Row className="w-100">
                        <Col
                            xs={12}
                            md={6}
                            className="pb-0 pt-1 pb-md-3 pt-md-3 d-flex align-items-center justify-content-center justify-content-md-start"
                        >
                            <LazyLoad><a href={redirectPath("/")}><img src="/images/logo.png" alt="Wunschkennzeichen Zulassungsservice"/></a></LazyLoad>
                        </Col>
                        <Col
                            xs={12}
                            md={6}
                            className="py-3 d-flex align-items-center justify-content-center justify-content-md-start"
                        >
                            <nav className="w-100 text-center text-md-right text-lg-center">
                                <a href={redirectPath("/kfz-kennzeichen")}>Kennzeichen konfigurieren!</a>
                            </nav>
                        </Col>
                    </Row>
                </header>
            </>
        );
    }
}
