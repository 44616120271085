import React from "react";
import LazyLoad from 'react-lazyload';

interface IconBoxProps {
    img: string;
    href: string;
    text?: string;
}

export class IconBox extends React.Component<IconBoxProps> {
    render() {
        return (
            <a href={this.props.href} className={"text-decoration-none w-100 h-100"}>
                <span className={"hover-bg-lightgray icon-box"} style={{
                    cursor: "pointer",
                    border: "1px solid black",
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    backgroundColor: "#cbcbcb",
                    height: "100%",
                    width: "100%",
                    minHeight: "200px",
                    minWidth: "200px",
                    transition: "all 0.3s ease-in-out",
                }}>
                    <div className={"mt-3 pb-3"} style={{marginBottom: "auto"}}>
                    <LazyLoad height={150}>
                        <img style={{maxWidth: "100px", maxHeight: "150px", marginBottom: "auto"}} src={this.props.img} alt={this.props.text}/>
                    </LazyLoad>
                    </div>
                    {this.props.text}
                </span>
            </a>
        );
    }
}