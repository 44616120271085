import React from "react";
import {redirectPath} from "../config/productDetails";
import LazyLoad from "react-lazyload";

export class Footer extends React.Component {
    render() {
        return (
            <React.Fragment>
                <footer className={"mt-5 py-3 px-1 text-center text-md-left"}>
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-12 col-sm-6 col-md-4 col-xl-2"}>
                                <h5>Kundenservice</h5>
                                <p>
                                    So erreichen Sie uns:
                                </p>
                                <p>
                                    Tel.: <a href={"tel:015151796517"}>0151-51796517</a><br/>
                                    Mail: <a
                                    href={"mailto:info@kennzeichen-zulassungsservice.de"}>info@kennzeichen-zulassungsservice.de</a>
                                </p>
                                <p>
                                    Montag-Freitag 8-17 Uhr
                                </p>
                            </div>
                            <div className={"col-12 col-sm-6 col-md-4 col-xl-2"}>
                                <h5>Informationen</h5>
                                <ul>
                                    <li>
                                        <a href={redirectPath("/kontakt")}>Kontakt</a>
                                    </li>
                                    <li>
                                        <a href={redirectPath("/faq")}>FAQ</a>
                                    </li>
                                    <li>
                                        <a href={redirectPath("/agb")}>AGB</a>
                                    </li>
                                    <li>
                                        <a href={redirectPath("/impressum")}>Impressum</a>
                                    </li>
                                    <li>
                                        <a href={redirectPath("/datenschutz")}>Datenschutz</a>
                                    </li>
                                    <li>
                                        <a href={redirectPath("/widerrufsrecht")}>Widerrufsrecht</a>
                                    </li>
                                    <li>
                                        <a href={redirectPath("/lieferbedingungen")}>Versandbedingungen</a>
                                    </li>
                                </ul>
                            </div>
                            <div className={"col-12 col-sm-6 col-md-4 col-xl-2"}>
                                <h5>DIN-geprüft</h5>
                                <LazyLoad>
                                    <img style={{maxWidth: "150px"}} src={"/images/din.jpg"} alt={"DIN-geprüfte" +
                                    " Nummernschilder"}/>
                                </LazyLoad>
                                <strong>1M11959/13</strong>
                            </div>
                            <div className={"col-12 col-sm-6 col-md-4 col-xl-2"}>
                                <h5>Sicher!</h5>
                                <p>
                                    Bei uns kaufen unsere
                                    Kunden sicher ein!
                                </p>
                            </div>
                            <div className={"col-12 col-sm-6 col-md-4 col-xl-2"}>
                                <h5>Versand</h5>
                                <LazyLoad>
                                    <img style={{maxWidth: "150px"}} src={"/images/dpd.jpg"} alt={"Zahlungsarten"}/>
                                </LazyLoad>
                                <p>
                                    Selbstabholung während und außerhalb der Öffnungszeiten nach telefonischer
                                    Rücksprache möglich.
                                </p>
                            </div>
                            <div className={"col-12 col-sm-6 col-md-4 col-xl-2"}>
                                <h5>Zahlung</h5>
                                <LazyLoad>
                                    <img style={{maxWidth: "250px"}} src={"/images/payment.png"} alt={"Zahlungsarten"}/>
                                </LazyLoad>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}
