import React from "react";

export const redirectPath = (uri: string): string => {
    return `https://kennzeichen-zulassungsservice.de${uri}`;
};

export type ProductDetailType = {
    title: string;
    img: string;
    href?: string;
    text: any;
    buttonText?: string;
};

const productDetails: ProductDetailType[] = [
    {
        title: "Saisonkennzeichen",
        img: "/images/produktgalerie/saison.jpg",
        href: redirectPath("/produkt/autokennzeichen/?saison=true"),
        text:
            <>
                Ob Sommer- oder Winterfahrzeug, Auto, Wohnmobil oder Motorrad. Jeder Fahrzeugbesitzer hat
                die Möglichkeit seine Fahrzeuge für einen bestimmten Nutzungszeitraum zuzulassen.
                Das bedeutet, dass Kfz-Steuer und Versicherungsbeiträge nur für diese festgelegte Zeitspanne fällig
                sind.
                Im Gegensatz dazu büßen Sie viel Geld ein, wenn Sie beispielsweise ein winteruntaugliches Fahrzeug
                ganzjährig zulassen, es aber nur im Sommer fahren.
                Vorteilhaft wäre in diesem Fall ein Saison-Kennzeichen mit dem Zeitraum 04-10. Dann dürfen Sie Ihr
                Fahrzeug ab dem 01. April bis Ende Oktober auf öffentlichen Straßen nutzen und zahlen entsprechend
                weniger Steuern und Versicherung.
            </>,
        buttonText: "Saisonkennzeichen zusammenstellen"
    },
    {
        title: "E-Kennzeichen",
        img: "/images/produktgalerie/e.jpg",
        href: redirectPath("/produkt/autokennzeichen/?e=true"),
        text:
            <>
                Sie fahren ein Elektro- oder Hybridfahrzeug? Perfekt! Sie tun nicht nur der Umwelt etwas Gutes, sondern
                haben auch das Privileg, ein "E" auf Ihrem Kennzeichen zu platzieren. Das verschafft Ihnen mancherorts
                sogar
                kostenfreie Parkplätze. Das E wird auf Ihrem Kennzeichen direkt hinter der Zahlenkombination platziert
                und kann mit einem Saison- oder steuerbefreiten Kennzeichen kombiniert werden. Ihr E-Kennzeichen darf
                aus einer Kombination von bis zu 8 Zahlen und Buchstaben bestehen.
            </>,
        buttonText: "E-Kennzeichen online konfigurieren"
    },
    {
        title: "H-Kennzeichen",
        img: "/images/produktgalerie/produkt2.jpg",
        href: redirectPath("/produkt/autokennzeichen/?h=true"),
        text:
            <>
                Historische Fahrzeuge sind nicht nur ein Hingucker, sie dürfen auch, trotz ihrer Abgaswerte,
                durch manche Umweltzonen fahren und Steuervorteile genießen. Sollte Ihr Fahrzeug also älter als 30 Jahre
                sein,
                Kennzeichnen Sie es mit einem "H". <a target={'_blank'} href={redirectPath("/zulassung-von-oldtimern")}>Lesen
                Sie hier
                mehr über die Zulassung von Oldtimern.</a>
                Die Kennzeichen unterscheiden sich in ihrer Größe voneinander, weshalb wir Ihnen eine breite Auswahl
                erlauben. Für eine einfache Montage können Sie sogar kostenfrei Löcher vorstanzen lassen!
            </>,
        buttonText: "Jetzt H-Kennzeichen konfigurieren"
    },
    {
        title: "Grüne Kennzeichen",
        img: "/images/produktgalerie/produkt6.jpg",
        href: redirectPath("/produkt/autokennzeichen/?steuerbefreit=true"),
        text:
            <>
                Sie haben ein Fahrzeug mit einem grünen Kennzeichen gesehen? Vielleicht einen Traktor? Dann wollen Sie
                sicher wissen, was es damit auf sich
                hat. Grüne Kennzeichen weisen auf die Steuerbefreiung hin. Landwirtschaftliche Fahrzeuge oder auch
                Anhänger und Wohnmobile haben oft das Recht, ein grünes Kennzeichen zu beantragen.
                <a target={'_blank'}
                   href={"https://www.gesetze-im-internet.de/kraftstg/__3.html"}>
                    Lesen Sie hier mehr zur aktuellen Gesetzeslage.</a>
            </>,
        buttonText: "Jetzt konfigurieren"
    }
];


export {productDetails};